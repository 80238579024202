import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    icon,
    title,
    textWhite,
    textRedirect,
    textBig,
    textSmall,
    textSmallMobile,
    textSmallDesktop,
    button,
} from './winter-contest-summary-header.module.scss';

import IconSent from '../../assets/images/svg/icon_completed.svg';

interface IWinterContestSummaryHeader {
    className?: string;
    isRedirected?: boolean;
}

const WinterContestSummaryHeader: React.FC<IWinterContestSummaryHeader> = ({
    className,
    isRedirected,
}) => {
    const { t } = useI18next();

    return (
        <div className={`${className} ${container}`}>
            <IconSent className={icon} />

            {isRedirected ? (
                <>
                    <h1 className={title}>
                        <span className={`${textWhite} ${textRedirect}`}>
                            {t('winter.contest.thank.you.header.1.redirected')}
                        </span>
                    </h1>
                    <p className={textSmall}>{t('winter.contest.thank.you.header.3.redirected')}</p>
                </>
            ) : (
                <>
                    <h1 className={title}>
                        <span className={textWhite}>{t('winter.contest.thank.you.header.1')}</span>
                        <span className={textBig}>{t('winter.contest.thank.you.header.2')}</span>
                    </h1>
                    <p className={`${textSmall} ${textSmallDesktop}`}>{t('winter.contest.thank.you.header.3')}</p>
                    <p className={`${textSmall} ${textSmallMobile}`}>{t('winter.contest.thank.you.header.3.mobile')}</p>
                    <a href="https://soplica.pl/grzance" className={button}>{t('winter.contest.thank.you.header.button')}</a>
                </>
            )}
        </div>
    );
};

export default WinterContestSummaryHeader;
