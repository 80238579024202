// extracted by mini-css-extract-plugin
export var button = "winter-contest-summary-header-module--button--21e45";
export var container = "winter-contest-summary-header-module--container--5a074";
export var errorBlink = "winter-contest-summary-header-module--error-blink--bf9ac";
export var icon = "winter-contest-summary-header-module--icon--29f9c";
export var textBig = "winter-contest-summary-header-module--text-big--63f33";
export var textRedirect = "winter-contest-summary-header-module--text-redirect--84487";
export var textSmall = "winter-contest-summary-header-module--text-small--9aed1";
export var textSmallDesktop = "winter-contest-summary-header-module--text-small-desktop--ad7da";
export var textSmallMobile = "winter-contest-summary-header-module--text-small-mobile--3d7f2";
export var textWhite = "winter-contest-summary-header-module--text-white--4bf69";
export var title = "winter-contest-summary-header-module--title--0535a";