const staticFiles = {
    labelRegulations: '/Regulamin_aktywacji_personalizowane_etykiety_Soplica_2023.pdf',
    lemonContestRegulations: '/Regulamin_Konkursu_Cytrynowe_Polaczenia.pdf',
    weddingContestRegulations: '/Regulamin_Konkursu_Wymarzone_Wesele.pdf',
    oldWinterContestRegulations: '/Regulamin_Konkurs_Zimowy.pdf',
    winterContestRegulations: '/wybieramy-smak-zimy.pdf',
    zabkaContestRegulations: '/regulamin-konkursu-ktora-soplica-rzadzi-w-zabce-01052024.pdf',
    akcjaCytrynowkaRegulations: '/Regulamin_Konkurs_dla_pracownikow_i_wlascicieli_sklepow_z_zezwoleniem_na_sprzedaz_alkoholu_29.05.2024.pdf',
    summerContestRegulations: '/REGULAMIN_KONKURSU_Soplica_rządzi_smakiem_Wybieramy_smak_lata_03.06.2024.pdf',
};

export default staticFiles;
