// extracted by mini-css-extract-plugin
export var colourBar = "winter-contest-results-module--colourBar--69204";
export var errorBlink = "winter-contest-results-module--error-blink--4c580";
export var flavourContainer = "winter-contest-results-module--flavour-container--ee8de";
export var flavourImage = "winter-contest-results-module--flavour-image--03c3e";
export var flavourResult = "winter-contest-results-module--flavour-result--a389d";
export var flavourTop = "winter-contest-results-module--flavour-top--9f5f3";
export var percentText = "winter-contest-results-module--percentText--e33c5";
export var resultsBar = "winter-contest-results-module--results-bar--fa700";
export var resultsInnerWrapper = "winter-contest-results-module--results-inner-wrapper--b251c";
export var resultsWrapper = "winter-contest-results-module--results-wrapper--e800c";
export var textBig = "winter-contest-results-module--text-big--fe2a4";
export var textGold = "winter-contest-results-module--text-gold--bb117";
export var textSmall = "winter-contest-results-module--text-small--37fd4";
export var textWhite = "winter-contest-results-module--text-white--274e2";
export var title = "winter-contest-results-module--title--2badc";