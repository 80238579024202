import React, { useEffect, useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
    title,
    textSmall,
    textBig,
    resultsWrapper,
    resultsInnerWrapper,
    flavourResult,
    flavourTop,
    flavourContainer,
    flavourImage,
    flavourName,
    textGold,
    textWhite,
    resultsBar,
    percentText,
    colourBar,
} from './winter-contest-results.module.scss';
import { IFlavour } from '../../models/flavour';
import { getFlavourResults } from "../../utils/get-flavour-results";

interface IWinterContestResultsProps {
    className?: string;
    flavours: IFlavour[];
    queryResults?: string;
}

const WinterContestResults: React.FC<IWinterContestResultsProps> = ({
    className,
    flavours,
    queryResults,
}) => {
    const { t } = useI18next();
    const [hasResults, setHasResults] = useState(false);

    const flavoursWithResults = queryResults ? getFlavourResults(flavours, queryResults) : [];

    useEffect(() => {
        if (hasResults) return;
        setHasResults(!!queryResults);
    }, [queryResults, hasResults]);

    if (!hasResults || flavoursWithResults.length <= 0) return null;

    return (
        <div className={`${className}`}>
            <h2 className={title}>
                <span className={textSmall}>{t('winter.contest.thank.you.results.1.rest')}</span>
                <span className={textBig}>{t('winter.contest.thank.you.results.2')}</span>
            </h2>
            <div className={resultsWrapper}>
                {flavoursWithResults.map((flavour, index) => {
                    const image = flavour.image && getImage(flavour.image.localFile);
                    return (
                        <div
                            key={`flavour-${index}`}
                            className={`${flavourResult} ${index < 3 ? flavourTop : ''}`}
                        >
                            <div className={flavourContainer}>
                                {image && (
                                    <GatsbyImage
                                        className={flavourImage}
                                        alt={flavour.image.alternativeText}
                                        image={image}
                                    />
                                )}
                                <p className={flavourName}>
                                    <span className={textGold}>
                                        {t('winter.contest.form.bottle')}
                                    </span>
                                    <span className={textWhite}>{flavour.name}</span>
                                </p>
                            </div>
                            <div className={resultsBar}>
                                <div className={resultsInnerWrapper}>
                                    <span className={percentText}>
                                        {flavour.result}% {t('winter.contest.thank.you.results.3')}
                                    </span>
                                    <div
                                        className={colourBar}
                                        style={{ width: `${parseFloat(flavour.result) < 95 ? parseFloat(flavour.result) + 5 : flavour.result}%` }}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default WinterContestResults;
