import { IFlavour, IResultFlavour } from "../models/flavour";

export function getFlavourResults(flavours: IFlavour[], query: string) {
    const values = query.split(',');
    const valuesMap: { flavour: string; result: string }[] = [];
    const flavoursWithResults: IResultFlavour[] = [];

    values.forEach((value, index) => {
        const valueArr = value.split(':');
        valuesMap[index] = { flavour: valueArr[0], result: valueArr[1] };
    });

    flavours.forEach((flavour) => {
        const result = valuesMap.find((value) => value.flavour === flavour.value.toString());
        flavoursWithResults.push({ ...flavour, result: result ? result.result : '0' });
    });

    flavoursWithResults.sort((a, b) => parseFloat(b.result) - parseFloat(a.result));
    return flavoursWithResults;
}