import React from 'react';
import { graphql } from 'gatsby';

import {
    layout,
    summaryHeader,
    summaryHeaderPadding,
    results,
    contact,
} from './winter-contest-closed.module.scss';

import { IPage } from '../models/page.model';
import { IFlavour } from '../models/flavour';

import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import WinterContestContact from '../components/molecules/winter-contest-contact';
import WinterContestBackground from '../components/organisms/winter-contest-background';
import WinterContestResults from '../components/organisms/winter-contest-results';
import WinterContestSummaryHeader from '../components/molecules/winter-contest-summary-header';

interface IWinterContestPage {
    readonly data: {
        page: IPage | null;
        flavours: {
            edges: {
                node: IFlavour;
            }[];
        };
    };
}

const WinterContestPage: React.FC<IWinterContestPage> = ({ data }) => {
    const { page, flavours } = data;

    const queryResults = '1:16,2:22,3:7,4:9,5:4,6:8,7:12,8:9,9:8,10:6';

    return (
        <MainLayout className={layout} page={page}>
            <WinterContestBackground
                isWinnerThankYouView={true}
                isBubblesVisible={true}
                isBorderVisible={true}
                isSnowVisible={true}
            />
            <WinterContestSummaryHeader
                isRedirected={false}
                className={`${summaryHeader} ${!queryResults ? summaryHeaderPadding : ''}`}
            />
            <WinterContestResults
                queryResults={queryResults}
                flavours={getNodes(flavours)}
                className={results}
            />
            <WinterContestContact className={contact} />
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        flavours: allStrapiWinterFlavour(filter: { locale: { eq: $language } }) {
            edges {
                node {
                    name
                    value
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, quality: 99)
                            }
                        }
                        alternativeText
                    }
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default WinterContestPage;
